import { Component, Emit, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Validator } from '@/App/Mixins'
import CommonForm from './Forms/Common/Common-Form.vue'
import CustomForm from './Forms/Custom/Custom-Form.vue'
import { DashboardTemplatesService } from '@/App/Services/DashboardTemplatesService'
import { ConfirmDialogOptions, Notification } from '@/store/interfaces/shared.interfaces'
import { WidgetForm } from '@/store/interfaces/widget.interface'
import { WidgetSensorType, WidgetVisualType } from '@/types'
import { DashboardTemplateItem } from '@/App/Services/interfaces/dashboardTemplateItem.interface'
import { VuetifySelect } from '@/interfaces/vuetify'


const sharedStore = namespace('Shared')

@Component({
    components: { CommonForm, CustomForm }
})
export default class DashboardTemplatesDialog extends Mixins(Validator) {
    $refs!: {
        commonForm: HTMLFormElement,
        customForm: HTMLFormElement,
        iFrameForm: HTMLFormElement,
    }

    @sharedStore.Action
    private readonly confirm: (options: ConfirmDialogOptions) => Promise<boolean>

    @sharedStore.Mutation
    private setNotification: (notification: Notification) => void

    public isOpen = false
    public isLoading = false
    public dashboardTemplate: DashboardTemplate = {
        name: '',
        widgets: []
    }
    public durationItems: VuetifySelect[] = [
        { value: 300, text: '5 min' },
        { value: 900, text: '15 min' },
        { value: 1800, text: '30 min' },
        { value: 3600, text: '60 min' }
    ]
    public form: {id: string; isValid: boolean}[] = []
    private customFormNames: string[] = ['Custom', 'Temperature', 'Voltage', 'Humidity', 'Light (lux)']
    public dashboardTemplateId: string | null = null

    public onDashboardTemplateAdd(): void {
        const id = (Math.random() * (1000 - 1) + 1).toString()
       this.dashboardTemplate.widgets.push({
           id,
           color: '#f44336',
           highLevelColor: '#f44336',
           highLevelValue: 0,
           lowLevelColor: '#f44336',
           lowLevelValue: 0,
           mathExpression: '',
           unit: '',
           midLevelColor: '#f44336',
           midLevelValue: 0,
           name: '',
           postfix: '',
           prefix: '',
           rounding: 0,
           sensorName: '',
           sensorType: 'Custom',
           showLastUpdateTime: false,
           visualType: 'tiles',
           visualTypeMaxValue: 220,
           visualTypeMinValue: 0,
           visualTypeTickInterval: 20,
           isValid: false,
           isMonitoring: false,
           monitoringDuration: 0
       })
    }

    public async onDashboardTemplateRemove(id: string) {
        const title = this.$t('dashboardTemplatesPage.removeText').toString()
        const text = `
        ${this.$t('dashboardTemplatesPage.confirmTitleText2')}<br/>
    `
        if (await this.confirm({ title, text })) {
            try {
                this.form = this.form.filter(f => f.id !== id)
                this.dashboardTemplate.widgets = this.dashboardTemplate.widgets.filter(w => w.id !== id)
            } catch {}
        }
    }

    public async open(): Promise<void> {
        this.isOpen = true
    }

    public close(): void {
        this.isOpen = false
        this.dashboardTemplate = {
            name: '',
            widgets: []
        }
        this.dashboardTemplateId = null
    }
    public submit(): void {
        this.dashboardTemplateId ? this.update() : this.save()
        this.isOpen = true
    }

    public async edit(item: DashboardTemplateItem): Promise<void> {
        this.dashboardTemplateId = item._id

        delete item._id

        this.dashboardTemplate = {
            name: item.name,
            widgets: item.widgets.map(widget => {
                const candidate = {
                    isValid: true,
                    id: widget._id,
                    ...widget
                }
                delete candidate._id
                return candidate
            })
        }

        await new Promise(r => setTimeout(r, 500))
        this.dashboardTemplate.widgets.forEach((w, idx) => {
            //@ts-ignore
            this.$refs.commonForm[idx].fillForm({
                sensorType: w.sensorType,
                name: w.name
            })

            if (this.customFormNames.includes(w.sensorType)) {
                //@ts-ignore
                this.$refs.customForm[idx].fillForm({
                    color: w.color,
                    sensorName: w.sensorName,
                    lowLevelValue: w.lowLevelValue,
                    lowLevelColor: w.lowLevelColor,
                    midLevelValue: w.midLevelValue,
                    midLevelColor: w.midLevelColor,
                    highLevelValue: w.highLevelValue,
                    highLevelColor: w.highLevelColor,
                    mathExpression: w.mathExpression,
                    rounding: w.rounding,
                    prefix: w.prefix,
                    postfix: w.postfix,
                    unit: w.unit,
                    visualType: w.visualType,
                    visualTypeMaxValue: w.visualTypeMaxValue,
                    visualTypeMinValue: w.visualTypeMinValue,
                    visualTypeTickInterval: w.visualTypeTickInterval
                })
            }
        })

        this.isOpen = true
    }

    public async save(): Promise<void> {
        try {
            this.isLoading = true

          this.onDashboardTemplateUpdate(await DashboardTemplatesService.store({
                name: this.dashboardTemplate.name,
                widgets: this.dashboardTemplate.widgets.map(widget => {
                    delete widget.id
                    delete widget.isValid
                    return widget
                })
            }))

            this.setNotification({ text: this.$t('dashboardTemplatesPage.dashboardTemplatesDialog.dashboardCreatedNotificationText').toString() })

            this.close()
        } catch {} finally {
            this.isLoading = false
        }
    }

    public async update(): Promise<void> {
        try {
            if (!this.dashboardTemplateId) {
                return
            }

            this.isLoading = true

            this.onDashboardTemplateUpdate(await DashboardTemplatesService.update(this.dashboardTemplateId, {
                name: this.dashboardTemplate.name,
                widgets: this.dashboardTemplate.widgets.map(widget => {
                    delete widget.id
                    delete widget.isValid
                    return widget
                })
            }))

            this.setNotification({ text: this.$t('dashboardTemplatesPage.dashboardTemplatesDialog.dashboardUpdatedNotificationText').toString() })

            this.close()
        } catch {} finally {
            this.isLoading = false
        }
    }

    @Emit('onDashboardTemplateUpdate')
    onDashboardTemplateUpdate(res: DashboardTemplateItem): DashboardTemplateItem {
        return res
    }

    public formChanged(id: string, formData: Partial<WidgetForm>): void {
        const index = this.dashboardTemplate.widgets.findIndex(w => w.id === id)
        if (index === -1) return
        // @ts-ignore
        this.dashboardTemplate.widgets.splice(index, 1, { ...this.dashboardTemplate.widgets[index], ...formData })
    }

    get dialogTitle(): string {
        return this.dashboardTemplateId
            ? this.$t('dashboardTemplatesPage.dashboardTemplatesDialog.editText').toString()
            : this.$t('dashboardTemplatesPage.dashboardTemplatesDialog.titleText').toString()
    }

    get submitBtnText(): string {
        return this.dashboardTemplateId
            ? this.$t('widgetsPage.widgetDialog.updateText').toString()
            : this.$t('widgetsPage.widgetDialog.saveText').toString()
    }

    public isCustomFormShow(id: string): boolean {
        const widget = this.dashboardTemplate.widgets.find(w => w.id === id)
        if (!widget) return false
        return this.customFormNames.includes(widget.sensorType)
    }

    public isLastUpdateTimeSwitcherShow(id: string): boolean {
        const widget = this.dashboardTemplate.widgets.find(w => w.id === id)
        if (!widget) return false
        return widget.sensorType !== 'iFrame'
    }

    public isMonitoringShow(id: string): boolean {
        const widget = this.dashboardTemplate.widgets.find(w => w.id === id)
        if (!widget) return false
        return widget.sensorType !== 'iFrame'
    }

    get isSubmitFormDisabled(): boolean {
        for (const widget of this.dashboardTemplate.widgets) {
            if (!widget.isValid) return true
        }
        return this.isLoading || !this.dashboardTemplate.name || !this.dashboardTemplate.widgets.length
    }

}
interface DashboardTemplate {
    name: string
    widgets: {
        id: string
        name: string
        sensorType: WidgetSensorType
        sensorName: string
        color: string
        lowLevelValue: number
        lowLevelColor: string
        midLevelValue: number
        midLevelColor: string
        highLevelValue: number
        highLevelColor: string
        mathExpression: string
        rounding: number
        prefix: string
        postfix: string
        unit: string
        visualType: WidgetVisualType
        visualTypeMinValue: number
        visualTypeMaxValue: number
        visualTypeTickInterval: number
        showLastUpdateTime: boolean
        isValid: boolean
        isMonitoring: boolean
        monitoringDuration: number
    }[]
}

































