import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { DashboardTemplatesService } from '@/App/Services/DashboardTemplatesService'
import TableHeader from '@/App/Components/TableHeader/Table-Header.vue'
import DashboardTemplatesDialog from '../DashboardTemplatesDialog/Dashboard-Templates-Dialog.vue'
import { VuetifyTableHeader } from '@/interfaces/vuetify'
import { TableHeaderBtn } from '@/App/Components/TableHeader/TableHeader'
import { TABLE_HEADERS, TABLE_HEADER_BTN } from '@/App/Pages/DashboardTemplates/constants'
import { WidgetSensorType, WidgetVisualType } from '@/types'
import { DashboardTemplateItem } from '@/App/Services/interfaces/dashboardTemplateItem.interface'
import { ConfirmDialogOptions, Notification } from '@/store/interfaces/shared.interfaces'


const sharedStore = namespace('Shared')

@Component({
    components: { DashboardTemplatesDialog, TableHeader }
})
export default class DashboardTemplatesTable extends Vue {
    $refs: {
        dashboardTemplatesDialog: HTMLFormElement
    }

    @sharedStore.Mutation
    private readonly setNotification: (notification: Notification) => void

    @sharedStore.Action
    private readonly confirm: (options: ConfirmDialogOptions) => Promise<boolean>

    public search = ''
    public item: DashboardTemplateItem[] = []
    public widgets: DashboardTemplate[] = []
    public isLoading = false
    public headers: VuetifyTableHeader[] = []
    public headerActionsBtn: TableHeaderBtn[] = []

    public onEditDashboardTemplate(item: string): void {
        this.$refs.dashboardTemplatesDialog.edit(item)
    }

    public onSearch(text: string): void {
        this.search = text
    }

    public onDashboardTemplateUpdate(dashboardTemplate: DashboardTemplateItem): void {
        const candidate = this.item.find(item => item._id === dashboardTemplate._id)
        if (candidate) {
            this.item = this.item.map(item => {
                if (item._id === candidate._id) {
                    return dashboardTemplate
                } else {
                    return item
                }
            })
        } else {
            this.item.push(dashboardTemplate)
        }
    }

    public async onDelete(id: string) {
        const title = this.$t('dashboardTemplatesPage.confirmTitleText1').toString()
        const text = `
        ${this.$t('dashboardTemplatesPage.confirmTitleText2')}<br/>
        ${this.$t('dashboardTemplatesPage.confirmTitleText3')}<br/>
        ${this.$t('dashboardTemplatesPage.confirmTitleText4')}
    `
        if (await this.confirm({ title, text })) {
            try {
                await DashboardTemplatesService.destroy(id)

                this.item = this.item.filter(item => item._id !== id)

                this.setNotification({ text: this.$t('dashboardTemplatesPage.deletedNotificationText').toString() })
            } catch {}
        }
    }

    private async mounted(): Promise<void> {
        this.headers = TABLE_HEADERS
        this.headerActionsBtn = TABLE_HEADER_BTN
        try {
            this.isLoading = true

            this.item = await DashboardTemplatesService.fetch()

        } catch {} finally {
            this.isLoading = false
        }
    }
}
export interface DashboardTemplate {
    name: string
    widgets: {
        id: string
        name: string
        sensorType: WidgetSensorType
        sensorName: string
        color: string
        lowLevelValue: number
        lowLevelColor: string
        midLevelValue: number
        midLevelColor: string
        highLevelValue: number
        highLevelColor: string
        mathExpression: string
        rounding: number
        prefix: string
        postfix: string
        measurementUnits: string
        visualType: WidgetVisualType
        visualTypeMinValue: number
        visualTypeMaxValue: number
        visualTypeTickInterval: number
        showLastUpdateTime: boolean
        isValid: boolean
        isMonitoring: boolean
        monitoringDuration: number
    }[]
}

