import { Component, Mixins, Watch } from 'vue-property-decorator'
import { WidgetSensorType } from '@/types'
import { Validator } from '@/App/Mixins'

@Component
export default class CommonForm extends Mixins(Validator) {
  public formData: WidgetFormData = {
    sensorType: 'Custom',
    name: ''
  }
  public visualizationTypes: WidgetSensorType[] = [
    'Custom',
    'Temperature',
    'Voltage',
    'Humidity',
    'Light (lux)'
  ]

  public reset(): void {
  this.formData = {
      sensorType: 'Custom',
      name: ''
    }
  }

  public fillForm(formData: WidgetFormData): void {
    this.formData = formData
  }

  @Watch('formData', { deep: true })
  private widgetFormChanged(): void {
    this.$emit('formChanged', this.formData)
  }
}

interface WidgetFormData {
  sensorType: WidgetSensorType
  name: string
}
