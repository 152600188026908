import { HTTP } from '@/App/Utils/axios'
import { DashboardTemplateStoreRequest } from './interfaces/dashboardTemplateStoreInterface.interface'
import { DashboardTemplateItem } from './interfaces/dashboardTemplateItem.interface'
import { DashboardTemplateUpdateRequest } from '@/App/Services/interfaces/dashboardTemplateUpdateInterface'

export class DashboardTemplatesService {
    static async store( dashboardTemplate: DashboardTemplateStoreRequest ): Promise<DashboardTemplateItem> {
        try {
            const { data } = await HTTP.post( 'dashboard-templates', dashboardTemplate)

            return data
        } catch (e) {
            throw e
        }
    }

    static async fetch(): Promise<DashboardTemplateItem[]> {
        try {
            const { data } = await HTTP.get('dashboard-templates')

            return data
        } catch (e) {
            throw e
        }
    }

    static async update(id: string, widgets: DashboardTemplateUpdateRequest): Promise<DashboardTemplateItem> {
        try {
            const { data } = await HTTP.patch(`/dashboard-templates/${id}`, widgets)

            return data
        } catch (e) {
            throw e
        }
    }

    static async destroy(id: string): Promise<void> {
        try {
             await HTTP.delete(`/dashboard-templates/${id}`)
        } catch (e) {
            throw e
        }
    }
}
